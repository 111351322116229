.project-box {
    width: 1200px;
    margin: 20px auto;
    background-color: #FFF;
    height: calc(100% - 100px);
    box-sizing: border-box;
     ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
    }
    .project-add-block {
        box-sizing: border-box;
        width: 100%;
        padding: 20px 100px 0;
        .tab-title-header {
            .tab-title-txt {
                font-size: 18px;
                color: #222;
                font-weight: 400;
                margin: 0;
                padding: 0;
                line-height: 18px;
                padding-left: 13px;
                position: relative;
                &::before {
                    content: '';
                    background-color: #409eff;
                    height: 10px;
                    width: 6px;
                    position: absolute;
                    left: 0;
                    top: 4px;
                    border-radius: 3px;
                }
            }
        }
        .pannel-add-project {
            width: 100%;
            padding-top: 50px;
            .pannel-project-attrs-line {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .pannel-project-attr-item {
                    .right-item-sub {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-start;
                        .project-duration-attr {
                            .duration-title {
                                margin-bottom: 14px;
                                em {
                                    color: #FF0000;
                                }
                                span {
                                    color: #333333;
                                    margin-left: 8px;
                                }
                            }
                            .duration-value-box {
                                 ::v-deep .el-input {
                                    width: 150px;
                                }
                                span {
                                    margin-left: 8px;
                                }
                            }
                        }
                    }
                    width: 540px;
                    font-size: 14px;
                    margin-bottom: 40px;
                    &.right-item {
                        margin-left: 180px;
                    }
                    .attr-item-title {
                        .title-edit-icon {
                            font-size: 16px;
                            color: #409eff;
                            cursor: pointer;
                        }
                        margin-bottom: 14px;
                        em {
                            color: #FF0000;
                        }
                        span {
                            color: #333333;
                            margin-left: 8px;
                        }
                        .edit-input {
                            width: 100px;
                            margin-left: 10px;
                        }
                    }
                    .attr-item-value {
                         ::v-deep .el-cascader {
                            width: 100%;
                        }
                         ::v-deep .el-input__inner,
                         ::v-deep .el-textarea__inner {
                            background-color: #F5F7FA;
                            &::placeholder {
                                color: #999999;
                            }
                        }
                    }
                }
                .attr-item-flex {
                    width: 100%;
                }
            }
            .pannel-project-attrs-normal {
                width: 100%;
                margin-bottom: 40px;
                .attr-flex-header {
                    height: 30px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin-bottom: 12px;
                    &.space-between-position {
                        justify-content: space-between;
                    }
                }
                .attr-flex-content {
                     ::v-deep .evaluete-header {
                        background-color: #F5F7FA;
                    }
                     ::v-deep .el-textarea__inner {
                        background-color: #F5F7FA;
                    }
                    .upload-sucai {
                        .sucai-upload-area {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            .el-upload__tip {
                                margin-left: 20px;
                                font-size: 14px;
                                color: #999999;
                            }
                        }
                         ::v-deep .el-button {
                            background-color: #DCECFE;
                            border: 1px solid #DCECFE;
                            span {
                                color: #409EFF;
                                i.iconfont {
                                    font-size: 14px;
                                }
                            }
                        }
                         ::v-deep .el-upload-list {
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: flex-start;
                            align-items: center;
                        }
                         ::v-deep .el-upload-list__item {
                            width: 228px;
                            margin-right: 21px;
                        }
                         ::v-deep .el-upload-list__item-name {
                            .el-icon-document {
                                color: #409eff;
                                font-size: 18px;
                            }
                        }
                    }
                }
                .form-submit-buttons {
                    margin-top: 47px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}

.resource-dialog {
    .resource-dialog-content {
        width: 100%;
        .resource-select-line {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .select-item-line {
                margin-right: 20px;
            }
        }
        .resource-preview-content {
            margin-top: 30px;
            width: 100%;
            height: 600px;
            border: 1px solid #C4BCB7;
        }
    }
    .link-form-box {
        box-sizing: border-box;
        width: 100%;
        padding: 20px 20px 0;
    }
}

.applications-box {
    width: 100%;
    height: 100%;
    ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
    }
    ul,
    li {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    .apps-icons-list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .app-icon {
            width: 140px;
            height: 113px;
            margin-right: 20px;
            .app-icon-box {
                position: relative;
                cursor: pointer;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                &:hover,
                &.selected {
                    box-shadow: 0px 4px 8px 1px rgba(9, 95, 185, 0.17);
                    border-radius: 6px;
                    .app-remove-icon {
                        display: inline-block;
                    }
                }
                &.selected {
                    border: 1px solid rgba(9, 95, 185, 0.2);
                }
                .app-remove-icon {
                    display: none;
                    position: absolute;
                    top: -9px;
                    right: -9px;
                    font-size: 18px;
                    color: #FF0000;
                }
                .app-icon-img {
                    width: 64px;
                    height: 64px;
                    overflow: hidden;
                    border-radius: 24px;
                    margin-bottom: 15px;
                    img {
                        width: 64px;
                        height: 64px;
                    }
                    .add-app-icon {
                        width: 100%;
                        height: 100%;
                        background-color: #D3E8FF;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        i {
                            color: #409EFF;
                            font-size: 24px;
                        }
                    }
                }
                .app-name {
                    width: 96px;
                    height: 13px;
                    span {
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        text-align: center;
                        font-size: 12px;
                        line-height: 12px;
                        color: #333333;
                        font-weight: 400;
                        font-family: "Microsoft YaHei";
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }
        }
        .link-item {
            margin: 0 50px 21px 0;
            .link-item-box {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 260px;
                position: relative;
                cursor: pointer;
                box-sizing: border-box;
                &:hover {
                    .link-icon-remove {
                        display: block;
                    }
                }
                .link-icon {
                    display: inline-block;
                    width: 32px;
                    height: 32px;
                    margin-right: 11px;
                    background-size: 32px 32px;
                    &.coureware {
                        background-image: url("~@/assets/images/courseware.png");
                    }
                    &.peitao {
                        background-image: url("~@/assets/images/peitao.png");
                    }
                    &.person-resource {
                        background-image: url("~@/assets/images/person-resource.png");
                    }
                    &.textbook {
                        background-image: url("~@/assets/images/textbook.png");
                    }
                    &.training-center {
                        background-image: url("~@/assets/images/training-center.png");
                    }
                    &.test-center {
                        background-image: url("~@/assets/images/test-center.png");
                    }
                    &.micro-lesson {
                        background-image: url("~@/assets/images/micro-lesson.png");
                    }
                    &.animation-link {
                        background-image: url("~@/assets/images/animation.png");
                    }
                }
                .link-title {
                    display: inline-block;
                    width: 200px;
                    height: 16px;
                    line-height: 16px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    color: #333333;
                }
                .link-icon-remove {
                    display: none;
                    position: absolute;
                    top: 7px;
                    right: 0;
                    i {
                        font-size: 18px;
                        color: #FF0000;
                    }
                }
                .link-add-icon {
                    background-color: #DCECFE;
                    border: 1px solid #DCECFE;
                    color: #409eff;
                }
            }
        }
    }
}

.video-preview-box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.app-form-box {
    height: 100%;
    box-sizing: border-box;
    padding: 10px 0 10px 10px;
}